import React, { useState, useEffect } from 'react';
import './App.css';
import Papa from 'papaparse';
import {ScheduleItem} from "./types/ScheduleItem";
import {PlayoffOdds} from "./PlayoffOdds";
import SimpleTable from "./DivisionTable";
import "@fontsource/inter";

function App() {

    const [scheduleData, setScheduleData] = useState<ScheduleItem[]>([]);

    // Fetch and update the schedule data here
    useEffect(() => {
        // Assuming fetchScheduleData is a function that fetches the schedule data
        fetchScheduleData().then(data => setScheduleData(data));
    }, []);

    return (
    <div className="App">
        <div>
            <SimpleTable schedule={scheduleData} />
            {/*<PlayoffOdds schedule={scheduleData} />*/}
        </div>
    </div>
  );
}

const fetchScheduleData = async (): Promise<ScheduleItem[]> => {
    console.log('fetching csv')
    const response = await fetch('/data/schedule_enriched_1736122031.csv', {
        method: 'GET',
        cache: 'no-store'
    });
    const csvData = await response.text();

    return new Promise((resolve, reject) => {
        Papa.parse<ScheduleItem>(csvData, {
            header: true,
            transform: (value, field) => {
                // List of fields that should be numbers
                const numberFields = ['week', 'away_score', 'home_score', 'home_moneyline', 'away_moneyline', /* other numeric fields */];

                // Convert to number if the field is in the numberFields array
                return numberFields.includes(field as string) ? +value : value;
            },
            complete: (results) => {
                console.log("schedule", results.data)
                resolve(results.data);
            },
            error: (error: any) => {
                reject(error);
            }
        });
    });
};

export default App;
